import { addToFavorites, removeFromFavorites } from '../api/user/favoris.js';
import '../../styles/favoris/favorisIndex.css'; // Importez votre fichier CSS

document.addEventListener('DOMContentLoaded', () => {
    const favoriteButtons = document.querySelectorAll('.favorite-button');
    const deleteFavori = document.querySelectorAll('.deleteFavori');

    const favoriteClic = document.querySelectorAll('.favorite-clic');

    favoriteButtons.forEach(button => {
        button.addEventListener('click', async (event) => {
            event.preventDefault();
    
            const svgIcon = button.querySelector('.favoris');
            const productId = button.dataset.productId;
            const action = button.dataset.action; // 'add' ou 'remove'
    
            console.log('click ' + productId);
    
            try {
                let result;
                if (action === 'add') {
                    result = await addToFavorites(productId);
                    button.dataset.action = 'remove';
                    button.querySelector('[data-bs-toggle="tooltip"]').setAttribute('title', 'Retirer des favoris');
                    svgIcon.classList.add('svg-icon-select');
                } else if (action === 'remove') {
                    result = await removeFromFavorites(productId);
                    button.dataset.action = 'add';
                    button.querySelector('[data-bs-toggle="tooltip"]').setAttribute('title', 'Ajouter aux favoris');
                    svgIcon.classList.remove('svg-icon-select');
                }
    
                // Mettre à jour le badge des favoris
                //document.getElementById('badge-favoris').textContent = result.nb;
                let badge = document.querySelector('#badge-favoris-desktop');
                let badge2 = document.querySelector('#badge-favoris');
                
                badge.textContent = result.nb;
                badge2.textContent = result.nb;
                // Mettre à jour ou recréer le tooltip
                const tooltipElement = button.querySelector('[data-bs-toggle="tooltip"]');
                const tooltipInstance = bootstrap.Tooltip.getInstance(tooltipElement);
                if (tooltipInstance) {
                    tooltipInstance.dispose(); // Supprime l'ancien tooltip
                }
                new bootstrap.Tooltip(tooltipElement); // Crée un nouveau tooltip
    
                console.log('Action completed:', result);
            } catch (error) {
                console.error('Error handling favorite action:', error);
            }
        });
    });
    
    deleteFavori.forEach(button => {
        button.addEventListener('click', async function (event) {
            const productId = button.getAttribute('data-product');
            let productCountElement =  document.querySelector('#badge-favoris-desktop');
            let productCountElement2 =  document.querySelector('#badge-favoris');
          
            if (confirm('Are you sure you want to delete this product?')) {
                try {
                    const message = await removeFromFavorites(productId);

                    // Suppression du produit de l'interface utilisateur
                    const productElement = button.closest('.product-row');
                    if (productElement) {
                        productElement.remove();
                    }
                    // Décrémentez le compteur de produits
                    if (productCountElement) {
                        let currentCount = parseInt(productCountElement.textContent, 10);
                        if (!isNaN(currentCount) && currentCount > 0) {
                            productCountElement.textContent = currentCount - 1;
                            productCountElement2.textContent = currentCount - 1;
                        }
                    }
                 
                } catch (error) {
                    alert('An error occurred while trying to delete the product.');
                }
            }
        });
    });

    //Favoris Clic
    favoriteClic.forEach(button => {
        button.addEventListener('click', async (event) => {
            event.preventDefault();
    
            //const svgIcon = button.querySelector('.favoris');
            const productId = button.dataset.productId;
            const action = button.dataset.action; // 'add' ou 'remove'
            console.log(button);
            console.log('click ' + productId);
    
            try {
                let result;
                if (action === 'add') {
                    result = await addToFavorites(productId);
                    button.dataset.action = 'remove';
                    button.children[0].children[1].textContent = 'Retirer des favoris';
                    //button.querySelector('[data-bs-toggle="tooltip"]').setAttribute('title', 'Retirer des favoris');
                    //svgIcon.classList.add('svg-icon-select');
                } else if (action === 'remove') {
                    result = await removeFromFavorites(productId);
                    button.dataset.action = 'add';
                    button.children[0].children[1].textContent = 'Ajouter aux favoris';
                    //button.querySelector('[data-bs-toggle="tooltip"]').setAttribute('title', 'Ajouter aux favoris');
                    //svgIcon.classList.remove('svg-icon-select');
                }
                let badge = document.querySelector('#badge-favoris-desktop');
                let badge2 = document.querySelector('#badge-favoris');
                  
                badge.textContent = result.nb;
                badge2.textContent = result.nb;
           
    
              
    
                console.log('Action completed:', result);
            } catch (error) {
                console.error('Error handling favorite action:', error);
            }
        });
    });
});