// assets/js/api.js

export async function addToFavorites(productId) {
    try {
        const response = await fetch(`/api/favoris/add/${productId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', // Important pour inclure les cookies de session
        });

        if (!response.ok) {
            throw new Error('Failed to add to favorites js');
        }

        return await response.json();
    } catch (error) {
        console.error('Error adding to favorites:', error);
        throw error;
    }
}

export async function removeFromFavorites(productId) {
    try {
        const response = await fetch(`/api/favoris/remove/${productId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to remove from favorites');
        }

        return await response.json();
    } catch (error) {
        console.error('Error removing from favorites:', error);
        throw error;
    }
}